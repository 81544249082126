<template>
  <div>
    <div
      class="evaluatee-carousel mt-4"
      :class="{ 'noprint evaluatee-carousel-sticky': window_top > 318 }"
    >
      <div v-if="window_top > 318" class="current-evaluatee-label">
        <strong> Actualmente evaluando a: </strong>
      </div>
      <EvaluateeCarousel
        :key="key_evaluatee"
        :current_users="evaluatee_evaluation_list"
        :actualTest="actualTest"
        :sticky="window_top > 318 ? true : false"
        :skip_corrected="true"
        :evaluator_view="evaluator_view"
        @student_selcted="slotStudentSelected"
      ></EvaluateeCarousel>
    </div>
  </div>
</template>

<script>
import * as names from "@/store/names";
import { mapGetters } from "vuex";
export default {
  name: "SelectedEvaluatee",
  components: {
    EvaluateeCarousel: () => import("./EvaluateeCarousel"),
  },
  props: {
    actualTest: {
      type: Object,
    },
    evaluation: {
      type: Object,
      required: true,
    },
    evaluator_view: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      key_evaluatee: 0,
      window_top: 0,
    };
  },
  computed: {
    ...mapGetters({
      Tests: names.NEW_TESTS,
      sections_store: names.SECTIONS,
    }),
    section() {
      if (this.evaluation && this.evaluation.scope == 1)
        return this.sections_store.find(
          (x) => x.id == this.evaluation.scope_id
        );
      else return null;
    },
    evaluatee_evaluation_list() {
      let user_list = [];
      user_list = user_list.concat(this.evaluation.evaluatees);
      this.Tests.filter((x) => x.evaluation == this.evaluation.id).forEach(
        (test) => {
          user_list = user_list.concat(test.evaluatees);
        }
      );
      // if (this.section) {
      //   user_list = user_list.concat(this.section.students);
      // }
      return [...new Set(user_list)];
    },
  },
  methods: {
    onScroll(e) {
      this.window_top = e.target.documentElement.scrollTop;
    },
    slotStudentSelected(user_id) {
      this.$emit("selected_user", user_id);
    },
  },
  watch: {
    evaluatee_evaluation_list() {
      this.key_evaluatee++;
    },
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.onScroll);
  },
  created() {
    window.addEventListener("scroll", this.onScroll);
  },
};
</script>

<style scoped>
.evaluatee-carousel {
  background: var(--kl-menu-color);
  box-shadow: 0 1px 7px -4px var(--secondary-color);
  padding-top: 1.5em;
  padding-inline: 1em;
  transition: 0.2s;
  border-radius: 5px;
  display: flex;
}
.evaluatee-carousel-sticky {
  width: calc(100% + 20px);
  max-height: 62px;
  background: var(--kl-menu-color);
  color: white;
  box-shadow: 0 1px 7px -4px var(--secondary-color);
  padding-top: 1.5em;
  padding-inline: 1em;
  transition: 0.2s;
  position: fixed;
  top: 54px;
  left: 0;
  z-index: 300;
}
.current-evaluatee-label {
  /* transform: translateY(-30%); */
  margin-left: 0.5em;
  margin-right: 1em;
  /* width: calc(40% + 10px); */
  width: 250px;
}
</style>